/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Rfid = {
  __typename: "Rfid",
  accessRights?: string | null,
  category?: string | null,
  chargerRestricted?: boolean | null,
  chargers?: Array< number > | null,
  id: string,
  isActive: boolean,
  lastChange?: number | null,
  lastUsage?: number | null,
  limitCost?: number | null,
  limitEnd?: number | null,
  limitEnergy?: number | null,
  limitPeriod?: string | null,
  limitStart?: number | null,
  maxPower?: number | null,
  name?: string | null,
  rfid: string,
  siteId: number,
  siteIds?: Array< number > | null,
  tariffs?:  Array<TariffAssociation > | null,
  usedCost?: number | null,
  usedEnergy?: number | null,
};

export type TariffAssociation = {
  __typename: "TariffAssociation",
  active: boolean,
  priority: number,
  tariffId: number,
};

export type User = {
  __typename: "User",
  accessRights?: string | null,
  chargingControl?: string | null,
  company?: string | null,
  email?: string | null,
  id: string,
  name?: string | null,
  role: UserRole,
  secondaryId?: string | null,
  settings?: string | null,
  siteIds?: Array< number > | null,
};

export enum UserRole {
  admin = "admin",
  rfid = "rfid",
  root = "root",
  viewer = "viewer",
}


export type EngineWriteItem = {
  name: string,
  value: string,
};

export type EngineWriteResult = {
  __typename: "EngineWriteResult",
  clientRequestHandle?: string | null,
  itemList:  Array<EngineWriteResultItem >,
};

export type EngineWriteResultItem = {
  __typename: "EngineWriteResultItem",
  name: string,
  result?: boolean | null,
};

export type ExecCommandInput = {
  command: Command,
  ioArg?: CmdIoArgs | null,
  seqNr: number,
  serial?: string | null,
  siteId?: number | null,
  strArg?: string | null,
};

export enum Command {
  openSshTunnel = "openSshTunnel",
  rebootController = "rebootController",
  setInput = "setInput",
  setOutput = "setOutput",
  setParameter = "setParameter",
}


export type CmdIoArgs = {
  address: number,
  value: number,
};

export type ImportFileInput = {
  args?: string | null,
  fileType: ImportFileType,
  siteId: number,
};

export enum ImportFileType {
  TrendData = "TrendData",
}


export type ImportFileOutput = {
  __typename: "ImportFileOutput",
  id: string,
  url: string,
};

export type ModifyChargerInput = {
  coordinates?: Array< number | null > | null,
  deleteCharger?: boolean | null,
  id: string,
  name?: string | null,
  nominalPower?: number | null,
  numberofconnectors?: number | null,
  restrictAuth?: boolean | null,
};

export type ModifyChargerControlInput = {
  isActive?: boolean | null,
  maxPower?: number | null,
  mode?: ChargingMode | null,
  saveAsDefault?: boolean | null,
  targetEnergy?: number | null,
  targetTimestamp?: number | null,
  useAtNewSession?: boolean | null,
};

export enum ChargingMode {
  costOptimized = "costOptimized",
  early = "early",
  late = "late",
  pvOptimized = "pvOptimized",
  uniform = "uniform",
}


export type ModifyConnectorInput = {
  chargerId: number,
  connectorId: number,
  evseid?: string | null,
  isDc?: boolean | null,
  name?: string | null,
  nominalPower?: number | null,
  nrPhases?: number | null,
  plugType?: string | null,
};

export type ModifyRfidInput = {
  accessRights?: string | null,
  category?: string | null,
  chargerRestricted?: boolean | null,
  chargers?: Array< number | null > | null,
  id?: string | null,
  limitCost?: number | null,
  limitEnd?: number | null,
  limitEnergy?: number | null,
  limitPeriod?: string | null,
  limitStart?: number | null,
  maxPower?: number | null,
  name?: string | null,
  rfid?: string | null,
  siteId?: number | null,
  siteIds?: Array< number > | null,
};

export type ModifySiteInput = {
  cloudSettings?: string | null,
  id: string,
};

export type ModifyTariffInput = {
  chargerId?: number | null,
  costPerEnergy?: number | null,
  costPerPeriod?: number | null,
  id?: string | null,
  isActive?: boolean | null,
  name?: string | null,
  priority?: number | null,
  rfidId?: number | null,
  siteId: number,
  timeEnd?: number | null,
  timeStart?: number | null,
  validFrom?: number | null,
  validUntil?: number | null,
};

export type ModifyUserInput = {
  accessRights?: string | null,
  chargingControl?: string | null,
  company?: string | null,
  email?: string | null,
  id?: string | null,
  name?: string | null,
  role?: UserRole | null,
  settings?: string | null,
  siteIds?: Array< number | null > | null,
};

export type NewAlarmDataInput = {
  ackSource?: string | null,
  ackTimestamp?: number | null,
  alarmTimestamp: number,
  clearTimestamp?: number | null,
  description?: string | null,
  isActive: boolean,
  isFromMqtt?: boolean | null,
  siteId: number,
  source: string,
  value: number,
};

export type AlarmData = {
  __typename: "AlarmData",
  ackSource?: string | null,
  ackTimestamp?: number | null,
  alarmTimestamp: number,
  clearTimestamp?: number | null,
  description?: string | null,
  isActive: boolean,
  siteId: number,
  source: string,
  value: number,
};

export type NewChargerDataInput = {
  chargerCmdHasError?: boolean | null,
  chargerCmdResult?: string | null,
  chargerCmdSeqNr?: number | null,
  chargerId: number,
  connector?: number | null,
  current?: Array< number | null > | null,
  energyMeter?: number | null,
  offeredCurrent?: number | null,
  offeredPower?: number | null,
  pingResult?: number | null,
  power?: number | null,
  sessionEnergy?: number | null,
  siteId: number,
  soc?: number | null,
  timestamp: number,
  voltage?: Array< number | null > | null,
};

export type ChargerData = {
  __typename: "ChargerData",
  chargerCmdHasError?: boolean | null,
  chargerCmdResult?: string | null,
  chargerCmdSeqNr?: number | null,
  chargerId: number,
  connector?: number | null,
  current?: Array< number | null > | null,
  energyMeter?: number | null,
  offeredCurrent?: number | null,
  offeredPower?: number | null,
  pingResult?: number | null,
  power?: number | null,
  sessionEnergy?: number | null,
  siteId: number,
  soc?: number | null,
  timestamp: number,
  voltage?: Array< number | null > | null,
};

export type NewChargerStatusNotificationInput = {
  chargerId: number,
  connector: number,
  errorCode: string,
  id: string,
  info?: string | null,
  siteId: number,
  source: ChargerStatusNotificationSource,
  statusCode?: string | null,
  timestamp: number,
};

export enum ChargerStatusNotificationSource {
  Backend = "Backend",
  Charger = "Charger",
  Controller = "Controller",
}


export type ChargerStatusNotificationData = {
  __typename: "ChargerStatusNotificationData",
  chargerId: number,
  connector: number,
  errorCode: string,
  id: string,
  info?: string | null,
  siteId: number,
  source?: ChargerStatusNotificationSource | null,
  statusCode?: string | null,
  timestamp: number,
};

export type NewChargingSessionDataInput = {
  chargerId: number,
  connector: number,
  costs?: number | null,
  id: string,
  rfid: string,
  siteId: number,
  startDate?: number | null,
  startMeter?: number | null,
  stopDate?: number | null,
  stopMeter?: number | null,
  stopReason?: string | null,
  tariffId?: number | null,
};

export type ChargingSessionData = {
  __typename: "ChargingSessionData",
  chargerId: number,
  connector?: number | null,
  costs?: number | null,
  id: string,
  rfid: string,
  siteId: number,
  startDate?: number | null,
  startMeter?: number | null,
  stopDate?: number | null,
  stopMeter?: number | null,
  stopReason?: string | null,
  tariffId?: number | null,
};

export type NewReportDataInput = {
  customization?: string | null,
  error?: string | null,
  outputFormat: ReportFormat,
  reportId: number,
  result: string,
  siteId: number,
};

export enum ReportFormat {
  data = "data",
  email = "email",
  file = "file",
  link = "link",
}


export type ReportResult = {
  __typename: "ReportResult",
  customization?: string | null,
  error?: string | null,
  outputFormat: ReportFormat,
  reportId: number,
  result: string,
  siteId: number,
};

export type NewTrendDataInput = {
  costs?: number | null,
  energy: number,
  energymeter?: number | null,
  energyoffrel?: number | null,
  maxdisabled?: number | null,
  pmax?: number | null,
  regperiod: number,
  siteId: number,
  soc?: number | null,
  targetenergy?: number | null,
  tariff?: number | null,
  timestamp: number,
  tlId: number,
  trendpmax?: number | null,
};

export type TrendData = {
  __typename: "TrendData",
  chargerId?: number | null,
  costs?: number | null,
  energy: number,
  energymeter?: number | null,
  energyoffrel?: number | null,
  maxdisabled?: number | null,
  pmax?: number | null,
  power: number,
  regperiod: number,
  siteId: number,
  soc?: number | null,
  targetenergy?: number | null,
  tariff?: number | null,
  timestamp: number,
  tlId: number,
  trendpmax?: number | null,
};

export type RegisterDataListenerInput = {
  clientId: string,
  doRegister: boolean,
  forceUpdate?: boolean | null,
  rfid?: string | null,
  siteId?: number | null,
  subChargerPowerData?: boolean | null,
  subChargerStatusData?: boolean | null,
  subControllerChargerData?: boolean | null,
  subControllerChargerPing?: boolean | null,
  subControllerPowerData?: boolean | null,
};

export enum ChargerCommand {
  Custom = "Custom",
  Reset = "Reset",
  StartTransaction = "StartTransaction",
  StopTransaction = "StopTransaction",
  TriggerPowerControl = "TriggerPowerControl",
  Unlock = "Unlock",
}


export type UpdateLiveDataInput = {
  chargerAuth?: string | null,
  chargerCurI?: string | null,
  chargerCurP?: string | null,
  chargerCurU?: string | null,
  chargerDevMaxI?: string | null,
  chargerESession?: string | null,
  chargerETotal?: string | null,
  chargerSetMaxI?: string | null,
  chargerState?: string | null,
  commandResult?: string | null,
  commandSeqNr?: number | null,
  curConditionStatus?: string | null,
  curEnergy?: string | null,
  curEnergyMeterValues?: string | null,
  curInputStatus?: string | null,
  curModuleStatus?: string | null,
  curOutputStatus?: string | null,
  curOutputStatusPhysical?: string | null,
  curPower?: string | null,
  curTimeOfPeriod?: string | null,
  curTrendLevel?: string | null,
  pingResult?: string | null,
  regulationPriorityStatus?: string | null,
  siteId: number,
  targetValues?: string | null,
  timestamp: number,
};

export type LiveData = {
  __typename: "LiveData",
  chargerAuth?: string | null,
  chargerCurI?: string | null,
  chargerCurP?: string | null,
  chargerCurU?: string | null,
  chargerDevMaxI?: string | null,
  chargerESession?: string | null,
  chargerETotal?: string | null,
  chargerSetMaxI?: string | null,
  chargerState?: string | null,
  commandResult?: string | null,
  commandSeqNr?: number | null,
  curConditionStatus?: string | null,
  curEnergy?: string | null,
  curEnergyMeterValues?: string | null,
  curInputStatus?: string | null,
  curModuleStatus?: string | null,
  curOutputStatus?: string | null,
  curOutputStatusPhysical?: string | null,
  curPower?: string | null,
  curTimeOfPeriod?: string | null,
  curTrendLevel?: string | null,
  pingResult?: string | null,
  regulationPriorityStatus?: string | null,
  siteId: number,
  targetValues?: string | null,
  timestamp: number,
};

export type EngineStatus = {
  __typename: "EngineStatus",
  accessRights?: string | null,
  company?: string | null,
  id: string,
  name?: string | null,
  role: UserRole,
  siteId: number,
};

export type EngineReadResult = {
  __typename: "EngineReadResult",
  clientRequestHandle?: string | null,
  itemList:  Array<EngineReadItem >,
};

export type EngineReadItem = {
  __typename: "EngineReadItem",
  name: string,
  value?: string | null,
};

export type ChargerControl = {
  __typename: "ChargerControl",
  addedOn: number,
  id: string,
  isActive: boolean,
  maxPower?: number | null,
  mode: ChargingMode,
  targetEnergy?: number | null,
  targetTimestamp?: number | null,
};

export enum DataSource {
  Alarm = "Alarm",
  Charging = "Charging",
  Input = "Input",
  Output = "Output",
  Trend = "Trend",
}


export type ParameterData = {
  __typename: "ParameterData",
  parameter: string,
  siteName?: string | null,
  source?: ParameterSource | null,
  timestamp: number,
};

export enum ParameterSource {
  cloudCommand = "cloudCommand",
  email = "email",
  localUpload = "localUpload",
  reboot = "reboot",
}


export type ChargerStatusNotification = {
  __typename: "ChargerStatusNotification",
  chargerId: number,
  connector: number,
  errorCode: string,
  id: string,
  info?: string | null,
  source: ChargerStatusNotificationSource,
  statusCode?: string | null,
  timestamp: number,
};

export type Charger = {
  __typename: "Charger",
  chargepoint: string,
  connectionType?: string | null,
  coordinates?: Array< number | null > | null,
  firmware?: string | null,
  hasSmartCharging: boolean,
  id: string,
  isActive: boolean,
  lastChange?: number | null,
  lastMessage?: number | null,
  model?: string | null,
  name?: string | null,
  nominalPower?: number | null,
  numberofconnectors?: number | null,
  restrictAuth: boolean,
  serial?: string | null,
  tariffs?:  Array<TariffAssociation > | null,
  vendor?: string | null,
};

export type ChargingSession = {
  __typename: "ChargingSession",
  chargerId: number,
  connector: number,
  costs?: number | null,
  id: string,
  rfid?: string | null,
  rfidId?: number | null,
  siteId: number,
  startDate: number,
  startMeter: number,
  stopDate?: number | null,
  stopMeter?: number | null,
  stopReason?: string | null,
  tariffId?: number | null,
};

export type Connector = {
  __typename: "Connector",
  chargerId: number,
  connectorId: number,
  connectorStatus?: string | null,
  current?: Array< number | null > | null,
  energymeter?: number | null,
  evseid?: string | null,
  isDc?: boolean | null,
  lastChange?: number | null,
  lastUpdate?: number | null,
  name?: string | null,
  nominalPower?: number | null,
  nrPhases?: number | null,
  offeredCurrent?: number | null,
  offeredPower?: number | null,
  plugType?: string | null,
  power?: number | null,
  sessionenergy?: number | null,
  soc?: number | null,
  voltage?: Array< number | null > | null,
};

export type ImportFile = {
  __typename: "ImportFile",
  addedOn: number,
  fileType: ImportFileType,
  id: string,
  result?: string | null,
  status?: string | null,
};

export enum Aggregation {
  DAY = "DAY",
  HOUR = "HOUR",
  MONTH = "MONTH",
  NONE = "NONE",
}


export type IoAddress = {
  bus: number,
  channel: number,
  module: number,
};

export type IoData = {
  __typename: "IoData",
  bus: number,
  channel: number,
  count?: number | null,
  module: number,
  siteId: number,
  timestamp: number,
  value: number,
};

export type RefusedRfid = {
  __typename: "RefusedRfid",
  charger: number,
  counter: number,
  firstAttempt: number,
  id: string,
  lastAttempt: number,
  rfid: string,
};

export type Site = {
  __typename: "Site",
  cloudSettings?: string | null,
  company?: string | null,
  deviceSw?: string | null,
  deviceType: string,
  id: string,
  ipAddresses?: string | null,
  latitude?: number | null,
  longitude?: number | null,
  name?: string | null,
  serial?: string | null,
  tzOffset?: number | null,
};

export type Tariff = {
  __typename: "Tariff",
  costPerEnergy?: number | null,
  costPerPeriod?: number | null,
  id: string,
  isActive?: boolean | null,
  name: string,
  priority?: number | null,
  siteId?: number | null,
  timeEnd?: number | null,
  timeStart?: number | null,
  validFrom?: number | null,
  validUntil?: number | null,
};

export type ListRfidsBasicQueryVariables = {
  changesSince?: number | null,
  rfid?: string | null,
  siteId?: number | null,
};

export type ListRfidsBasicQuery = {
  listRfids?:  Array< {
    __typename: "Rfid",
    accessRights?: string | null,
    category?: string | null,
    chargerRestricted?: boolean | null,
    id: string,
    isActive: boolean,
    lastChange?: number | null,
    limitCost?: number | null,
    limitEnd?: number | null,
    limitEnergy?: number | null,
    limitPeriod?: string | null,
    limitStart?: number | null,
    maxPower?: number | null,
    name?: string | null,
    rfid: string,
    siteId: number,
  } | null > | null,
};

export type ListUsersBasicQueryVariables = {
};

export type ListUsersBasicQuery = {
  listUsers?:  Array< {
    __typename: "User",
    accessRights?: string | null,
    company?: string | null,
    email?: string | null,
    id: string,
    name?: string | null,
    role: UserRole,
    secondaryId?: string | null,
  } | null > | null,
};

export type EngineWriteMutationVariables = {
  clientRequestHandle?: string | null,
  engId: string,
  itemList: Array< EngineWriteItem >,
};

export type EngineWriteMutation = {
  engineWrite?:  {
    __typename: "EngineWriteResult",
    clientRequestHandle?: string | null,
    itemList:  Array< {
      __typename: "EngineWriteResultItem",
      name: string,
      result?: boolean | null,
    } >,
  } | null,
};

export type ExecCommandMutationVariables = {
  data: ExecCommandInput,
};

export type ExecCommandMutation = {
  execCommand?: boolean | null,
};

export type ImportFileMutationVariables = {
  data: ImportFileInput,
};

export type ImportFileMutation = {
  importFile?:  {
    __typename: "ImportFileOutput",
    id: string,
    url: string,
  } | null,
};

export type ModifyChargerMutationVariables = {
  data: ModifyChargerInput,
};

export type ModifyChargerMutation = {
  modifyCharger: boolean,
};

export type ModifyChargerControlMutationVariables = {
  chargerId: number,
  data: ModifyChargerControlInput,
  transactionId?: number | null,
};

export type ModifyChargerControlMutation = {
  modifyChargerControl: boolean,
};

export type ModifyConnectorMutationVariables = {
  data: ModifyConnectorInput,
};

export type ModifyConnectorMutation = {
  modifyConnector: boolean,
};

export type ModifyRfidMutationVariables = {
  data: ModifyRfidInput,
};

export type ModifyRfidMutation = {
  modifyRfid: boolean,
};

export type ModifySiteMutationVariables = {
  data: ModifySiteInput,
};

export type ModifySiteMutation = {
  modifySite: boolean,
};

export type ModifyTariffMutationVariables = {
  data: ModifyTariffInput,
};

export type ModifyTariffMutation = {
  modifyTariff: boolean,
};

export type ModifyUserMutationVariables = {
  data: ModifyUserInput,
};

export type ModifyUserMutation = {
  modifyUser: string,
};

export type NewAlarmDataMutationVariables = {
  data: NewAlarmDataInput,
};

export type NewAlarmDataMutation = {
  newAlarmData?:  {
    __typename: "AlarmData",
    ackSource?: string | null,
    ackTimestamp?: number | null,
    alarmTimestamp: number,
    clearTimestamp?: number | null,
    description?: string | null,
    isActive: boolean,
    siteId: number,
    source: string,
    value: number,
  } | null,
};

export type NewChargerDataMutationVariables = {
  data: NewChargerDataInput,
};

export type NewChargerDataMutation = {
  newChargerData:  {
    __typename: "ChargerData",
    chargerCmdHasError?: boolean | null,
    chargerCmdResult?: string | null,
    chargerCmdSeqNr?: number | null,
    chargerId: number,
    connector?: number | null,
    current?: Array< number | null > | null,
    energyMeter?: number | null,
    offeredCurrent?: number | null,
    offeredPower?: number | null,
    pingResult?: number | null,
    power?: number | null,
    sessionEnergy?: number | null,
    siteId: number,
    soc?: number | null,
    timestamp: number,
    voltage?: Array< number | null > | null,
  },
};

export type NewChargerStatusNotificationMutationVariables = {
  data: NewChargerStatusNotificationInput,
};

export type NewChargerStatusNotificationMutation = {
  newChargerStatusNotification:  {
    __typename: "ChargerStatusNotificationData",
    chargerId: number,
    connector: number,
    errorCode: string,
    id: string,
    info?: string | null,
    siteId: number,
    source?: ChargerStatusNotificationSource | null,
    statusCode?: string | null,
    timestamp: number,
  },
};

export type NewChargingSessionDataMutationVariables = {
  data: NewChargingSessionDataInput,
};

export type NewChargingSessionDataMutation = {
  newChargingSessionData:  {
    __typename: "ChargingSessionData",
    chargerId: number,
    connector?: number | null,
    costs?: number | null,
    id: string,
    rfid: string,
    siteId: number,
    startDate?: number | null,
    startMeter?: number | null,
    stopDate?: number | null,
    stopMeter?: number | null,
    stopReason?: string | null,
    tariffId?: number | null,
  },
};

export type NewReportMutationVariables = {
  data: NewReportDataInput,
};

export type NewReportMutation = {
  newReport?:  {
    __typename: "ReportResult",
    customization?: string | null,
    error?: string | null,
    outputFormat: ReportFormat,
    reportId: number,
    result: string,
    siteId: number,
  } | null,
};

export type NewTrendDataMutationVariables = {
  data: NewTrendDataInput,
};

export type NewTrendDataMutation = {
  newTrendData?:  {
    __typename: "TrendData",
    chargerId?: number | null,
    costs?: number | null,
    energy: number,
    energymeter?: number | null,
    energyoffrel?: number | null,
    maxdisabled?: number | null,
    pmax?: number | null,
    power: number,
    regperiod: number,
    siteId: number,
    soc?: number | null,
    targetenergy?: number | null,
    tariff?: number | null,
    timestamp: number,
    tlId: number,
    trendpmax?: number | null,
  } | null,
};

export type RegisterDataListenerMutationVariables = {
  data: RegisterDataListenerInput,
};

export type RegisterDataListenerMutation = {
  registerDataListener?: number | null,
};

export type TriggerChargerCommandMutationVariables = {
  args?: string | null,
  chargerId: number,
  command: ChargerCommand,
  seqNr: number,
};

export type TriggerChargerCommandMutation = {
  triggerChargerCommand?: boolean | null,
};

export type UpdateLiveDataMutationVariables = {
  data: UpdateLiveDataInput,
};

export type UpdateLiveDataMutation = {
  updateLiveData?:  {
    __typename: "LiveData",
    chargerAuth?: string | null,
    chargerCurI?: string | null,
    chargerCurP?: string | null,
    chargerCurU?: string | null,
    chargerDevMaxI?: string | null,
    chargerESession?: string | null,
    chargerETotal?: string | null,
    chargerSetMaxI?: string | null,
    chargerState?: string | null,
    commandResult?: string | null,
    commandSeqNr?: number | null,
    curConditionStatus?: string | null,
    curEnergy?: string | null,
    curEnergyMeterValues?: string | null,
    curInputStatus?: string | null,
    curModuleStatus?: string | null,
    curOutputStatus?: string | null,
    curOutputStatusPhysical?: string | null,
    curPower?: string | null,
    curTimeOfPeriod?: string | null,
    curTrendLevel?: string | null,
    pingResult?: string | null,
    regulationPriorityStatus?: string | null,
    siteId: number,
    targetValues?: string | null,
    timestamp: number,
  } | null,
};

export type CheckRfidQueryVariables = {
  rfid: string,
};

export type CheckRfidQuery = {
  checkRfid?: string | null,
};

export type EngineGetStatusQueryVariables = {
  engId: string,
};

export type EngineGetStatusQuery = {
  engineGetStatus?:  {
    __typename: "EngineStatus",
    accessRights?: string | null,
    company?: string | null,
    id: string,
    name?: string | null,
    role: UserRole,
    siteId: number,
  } | null,
};

export type EngineReadQueryVariables = {
  clientRequestHandle?: string | null,
  engId: string,
  itemList: Array< string >,
};

export type EngineReadQuery = {
  engineRead?:  {
    __typename: "EngineReadResult",
    clientRequestHandle?: string | null,
    itemList:  Array< {
      __typename: "EngineReadItem",
      name: string,
      value?: string | null,
    } >,
  } | null,
};

export type GetChargerControlQueryVariables = {
  chargerId?: number | null,
  transactionId?: number | null,
};

export type GetChargerControlQuery = {
  getChargerControl?:  {
    __typename: "ChargerControl",
    addedOn: number,
    id: string,
    isActive: boolean,
    maxPower?: number | null,
    mode: ChargingMode,
    targetEnergy?: number | null,
    targetTimestamp?: number | null,
  } | null,
};

export type GetDataBoundariesQueryVariables = {
  dataSource: DataSource,
  rfid?: string | null,
  siteId?: number | null,
};

export type GetDataBoundariesQuery = {
  getDataBoundaries?: Array< number | null > | null,
};

export type GetParameterQueryVariables = {
  siteId: number,
};

export type GetParameterQuery = {
  getParameter?: string | null,
};

export type GetParameter2QueryVariables = {
  serial: string,
};

export type GetParameter2Query = {
  getParameter2?:  {
    __typename: "ParameterData",
    parameter: string,
    siteName?: string | null,
    source?: ParameterSource | null,
    timestamp: number,
  } | null,
};

export type ListAlarmsQueryVariables = {
  includeAcked?: boolean | null,
  siteId: number,
};

export type ListAlarmsQuery = {
  listAlarms?:  Array< {
    __typename: "AlarmData",
    ackSource?: string | null,
    ackTimestamp?: number | null,
    alarmTimestamp: number,
    clearTimestamp?: number | null,
    description?: string | null,
    isActive: boolean,
    siteId: number,
    source: string,
    value: number,
  } | null > | null,
};

export type ListChargerStatusNotificationsQueryVariables = {
  chargerId: number,
  connector?: number | null,
  endDate?: number | null,
  startDate?: number | null,
};

export type ListChargerStatusNotificationsQuery = {
  listChargerStatusNotifications:  Array< {
    __typename: "ChargerStatusNotification",
    chargerId: number,
    connector: number,
    errorCode: string,
    id: string,
    info?: string | null,
    source: ChargerStatusNotificationSource,
    statusCode?: string | null,
    timestamp: number,
  } >,
};

export type ListChargersQueryVariables = {
  changesSince?: number | null,
  rfid?: string | null,
  siteId?: number | null,
};

export type ListChargersQuery = {
  listChargers?:  Array< {
    __typename: "Charger",
    chargepoint: string,
    connectionType?: string | null,
    coordinates?: Array< number | null > | null,
    firmware?: string | null,
    hasSmartCharging: boolean,
    id: string,
    isActive: boolean,
    lastChange?: number | null,
    lastMessage?: number | null,
    model?: string | null,
    name?: string | null,
    nominalPower?: number | null,
    numberofconnectors?: number | null,
    restrictAuth: boolean,
    serial?: string | null,
    tariffs?:  Array< {
      __typename: "TariffAssociation",
      active: boolean,
      priority: number,
      tariffId: number,
    } > | null,
    vendor?: string | null,
  } | null > | null,
};

export type ListChargingSessionsQueryVariables = {
  chargerId?: number | null,
  connector?: number | null,
  endDate?: number | null,
  rfid?: string | null,
  siteId?: number | null,
  startDate?: number | null,
  user?: string | null,
};

export type ListChargingSessionsQuery = {
  listChargingSessions?:  Array< {
    __typename: "ChargingSession",
    chargerId: number,
    connector: number,
    costs?: number | null,
    id: string,
    rfid?: string | null,
    rfidId?: number | null,
    siteId: number,
    startDate: number,
    startMeter: number,
    stopDate?: number | null,
    stopMeter?: number | null,
    stopReason?: string | null,
    tariffId?: number | null,
  } | null > | null,
};

export type ListConnectorsQueryVariables = {
  changesSince?: number | null,
  rfid?: string | null,
  siteId?: number | null,
};

export type ListConnectorsQuery = {
  listConnectors?:  Array< {
    __typename: "Connector",
    chargerId: number,
    connectorId: number,
    connectorStatus?: string | null,
    current?: Array< number | null > | null,
    energymeter?: number | null,
    evseid?: string | null,
    isDc?: boolean | null,
    lastChange?: number | null,
    lastUpdate?: number | null,
    name?: string | null,
    nominalPower?: number | null,
    nrPhases?: number | null,
    offeredCurrent?: number | null,
    offeredPower?: number | null,
    plugType?: string | null,
    power?: number | null,
    sessionenergy?: number | null,
    soc?: number | null,
    voltage?: Array< number | null > | null,
  } | null > | null,
};

export type ListImportFilesQueryVariables = {
  siteId: number,
};

export type ListImportFilesQuery = {
  listImportFiles?:  Array< {
    __typename: "ImportFile",
    addedOn: number,
    fileType: ImportFileType,
    id: string,
    result?: string | null,
    status?: string | null,
  } | null > | null,
};

export type ListIoDataQueryVariables = {
  aggregation?: Aggregation | null,
  dataSource: DataSource,
  endDate?: number | null,
  ioList?: Array< IoAddress > | null,
  siteId: number,
  startDate?: number | null,
};

export type ListIoDataQuery = {
  listIoData?:  Array< {
    __typename: "IoData",
    bus: number,
    channel: number,
    count?: number | null,
    module: number,
    siteId: number,
    timestamp: number,
    value: number,
  } | null > | null,
};

export type ListRefusedRfidsQueryVariables = {
  siteId: number,
};

export type ListRefusedRfidsQuery = {
  listRefusedRfids?:  Array< {
    __typename: "RefusedRfid",
    charger: number,
    counter: number,
    firstAttempt: number,
    id: string,
    lastAttempt: number,
    rfid: string,
  } | null > | null,
};

export type ListRfidsQueryVariables = {
  changesSince?: number | null,
  rfid?: string | null,
  siteId?: number | null,
};

export type ListRfidsQuery = {
  listRfids?:  Array< {
    __typename: "Rfid",
    accessRights?: string | null,
    category?: string | null,
    chargerRestricted?: boolean | null,
    chargers?: Array< number > | null,
    id: string,
    isActive: boolean,
    lastChange?: number | null,
    lastUsage?: number | null,
    limitCost?: number | null,
    limitEnd?: number | null,
    limitEnergy?: number | null,
    limitPeriod?: string | null,
    limitStart?: number | null,
    maxPower?: number | null,
    name?: string | null,
    rfid: string,
    siteId: number,
    siteIds?: Array< number > | null,
    tariffs?:  Array< {
      __typename: "TariffAssociation",
      active: boolean,
      priority: number,
      tariffId: number,
    } > | null,
    usedCost?: number | null,
    usedEnergy?: number | null,
  } | null > | null,
};

export type ListSitesQueryVariables = {
};

export type ListSitesQuery = {
  listSites?:  Array< {
    __typename: "Site",
    cloudSettings?: string | null,
    company?: string | null,
    deviceSw?: string | null,
    deviceType: string,
    id: string,
    ipAddresses?: string | null,
    latitude?: number | null,
    longitude?: number | null,
    name?: string | null,
    serial?: string | null,
    tzOffset?: number | null,
  } | null > | null,
};

export type ListTariffsQueryVariables = {
  endDate?: number | null,
  rfid?: string | null,
  siteId?: number | null,
  startDate?: number | null,
};

export type ListTariffsQuery = {
  listTariffs?:  Array< {
    __typename: "Tariff",
    costPerEnergy?: number | null,
    costPerPeriod?: number | null,
    id: string,
    isActive?: boolean | null,
    name: string,
    priority?: number | null,
    siteId?: number | null,
    timeEnd?: number | null,
    timeStart?: number | null,
    validFrom?: number | null,
    validUntil?: number | null,
  } | null > | null,
};

export type ListTrendDataQueryVariables = {
  aggregation?: Aggregation | null,
  chargerId?: number | null,
  endDate?: number | null,
  rfid?: string | null,
  siteId: number,
  startDate?: number | null,
  tlId?: Array< number > | null,
};

export type ListTrendDataQuery = {
  listTrendData?:  Array< {
    __typename: "TrendData",
    chargerId?: number | null,
    costs?: number | null,
    energy: number,
    energymeter?: number | null,
    energyoffrel?: number | null,
    maxdisabled?: number | null,
    pmax?: number | null,
    power: number,
    regperiod: number,
    siteId: number,
    soc?: number | null,
    targetenergy?: number | null,
    tariff?: number | null,
    timestamp: number,
    tlId: number,
    trendpmax?: number | null,
  } | null > | null,
};

export type ListUsersQueryVariables = {
  userIds?: Array< number > | null,
};

export type ListUsersQuery = {
  listUsers?:  Array< {
    __typename: "User",
    accessRights?: string | null,
    chargingControl?: string | null,
    company?: string | null,
    email?: string | null,
    id: string,
    name?: string | null,
    role: UserRole,
    secondaryId?: string | null,
    settings?: string | null,
    siteIds?: Array< number > | null,
  } | null > | null,
};

export type TriggerReportQueryVariables = {
  customization?: string | null,
  outputFormat: ReportFormat,
  reportId: number,
  siteId: number,
};

export type TriggerReportQuery = {
  triggerReport?: boolean | null,
};

export type OnNewAlarmDataSubscriptionVariables = {
  siteId: number,
};

export type OnNewAlarmDataSubscription = {
  onNewAlarmData?:  {
    __typename: "AlarmData",
    ackSource?: string | null,
    ackTimestamp?: number | null,
    alarmTimestamp: number,
    clearTimestamp?: number | null,
    description?: string | null,
    isActive: boolean,
    siteId: number,
    source: string,
    value: number,
  } | null,
};

export type OnNewChargerDataSubscriptionVariables = {
  chargerId?: number | null,
  connector?: number | null,
  siteId?: number | null,
};

export type OnNewChargerDataSubscription = {
  onNewChargerData:  {
    __typename: "ChargerData",
    chargerCmdHasError?: boolean | null,
    chargerCmdResult?: string | null,
    chargerCmdSeqNr?: number | null,
    chargerId: number,
    connector?: number | null,
    current?: Array< number | null > | null,
    energyMeter?: number | null,
    offeredCurrent?: number | null,
    offeredPower?: number | null,
    pingResult?: number | null,
    power?: number | null,
    sessionEnergy?: number | null,
    siteId: number,
    soc?: number | null,
    timestamp: number,
    voltage?: Array< number | null > | null,
  },
};

export type OnNewChargerStatusNotificationSubscriptionVariables = {
  chargerId?: number | null,
  connector?: number | null,
  siteId?: number | null,
};

export type OnNewChargerStatusNotificationSubscription = {
  onNewChargerStatusNotification:  {
    __typename: "ChargerStatusNotificationData",
    chargerId: number,
    connector: number,
    errorCode: string,
    id: string,
    info?: string | null,
    siteId: number,
    source?: ChargerStatusNotificationSource | null,
    statusCode?: string | null,
    timestamp: number,
  },
};

export type OnNewChargingSessionDataSubscriptionVariables = {
  rfid?: string | null,
  siteId?: number | null,
};

export type OnNewChargingSessionDataSubscription = {
  onNewChargingSessionData:  {
    __typename: "ChargingSessionData",
    chargerId: number,
    connector?: number | null,
    costs?: number | null,
    id: string,
    rfid: string,
    siteId: number,
    startDate?: number | null,
    startMeter?: number | null,
    stopDate?: number | null,
    stopMeter?: number | null,
    stopReason?: string | null,
    tariffId?: number | null,
  },
};

export type OnNewReportSubscriptionVariables = {
  siteId: number,
};

export type OnNewReportSubscription = {
  onNewReport?:  {
    __typename: "ReportResult",
    customization?: string | null,
    error?: string | null,
    outputFormat: ReportFormat,
    reportId: number,
    result: string,
    siteId: number,
  } | null,
};

export type OnNewTrendDataSubscriptionVariables = {
  siteId: number,
};

export type OnNewTrendDataSubscription = {
  onNewTrendData?:  {
    __typename: "TrendData",
    chargerId?: number | null,
    costs?: number | null,
    energy: number,
    energymeter?: number | null,
    energyoffrel?: number | null,
    maxdisabled?: number | null,
    pmax?: number | null,
    power: number,
    regperiod: number,
    siteId: number,
    soc?: number | null,
    targetenergy?: number | null,
    tariff?: number | null,
    timestamp: number,
    tlId: number,
    trendpmax?: number | null,
  } | null,
};

export type OnUpdateLiveDataSubscriptionVariables = {
  siteId: number,
};

export type OnUpdateLiveDataSubscription = {
  onUpdateLiveData?:  {
    __typename: "LiveData",
    chargerAuth?: string | null,
    chargerCurI?: string | null,
    chargerCurP?: string | null,
    chargerCurU?: string | null,
    chargerDevMaxI?: string | null,
    chargerESession?: string | null,
    chargerETotal?: string | null,
    chargerSetMaxI?: string | null,
    chargerState?: string | null,
    commandResult?: string | null,
    commandSeqNr?: number | null,
    curConditionStatus?: string | null,
    curEnergy?: string | null,
    curEnergyMeterValues?: string | null,
    curInputStatus?: string | null,
    curModuleStatus?: string | null,
    curOutputStatus?: string | null,
    curOutputStatusPhysical?: string | null,
    curPower?: string | null,
    curTimeOfPeriod?: string | null,
    curTrendLevel?: string | null,
    pingResult?: string | null,
    regulationPriorityStatus?: string | null,
    siteId: number,
    targetValues?: string | null,
    timestamp: number,
  } | null,
};
